import { HttpStatusCode, isAxiosError } from 'axios';

import {
  QueryObserverSuccessResult,
  UseQueryResult,
} from '@headway/shared/react-query';

export function isSuccessQuery<T>(
  query: UseQueryResult<T>
): query is QueryObserverSuccessResult<T> {
  return query.isSuccess;
}

export const isUnauthenticatedError = (err: unknown): boolean => {
  if (!isAxiosError(err)) {
    return false;
  }
  return err.response?.status === HttpStatusCode.Unauthorized;
};

export const isDuplicateRequestError = (err: unknown): boolean => {
  if (!isAxiosError(err)) {
    return false;
  }
  return err.response?.status === HttpStatusCode.Conflict;
};
