import Cookies from 'js-cookie';
import { makeObservable, observable } from 'mobx';
import React from 'react';

import { AuthApi } from '@headway/api/resources/AuthApi';
import { UserApi } from '@headway/api/resources/UserApi';
import {
  identifyLoggedOutUser,
  trackEvent,
} from '@headway/shared/utils/analytics';
import { isServer } from '@headway/shared/utils/isServer';

import { SESSION_COOKIE_NAME } from '../constants/cookie';
import { identifyUser } from '../utils/analytics';
import { isUserRegistered } from '../utils/verifyAccount';

let store = null;

class Store {
  user = null;
  loginModalOpen = false;
  signupModalOpen = false;
  forgotPasswordModalOpen = false;
  resetPasswordModalOpen = false;
  gatedRedirectLocation = undefined;
  resetPasswordToken = null;
  inviteToken = null;

  constructor(initialData = {}) {
    makeObservable(this, {
      user: observable,
      loginModalOpen: observable,
      signupModalOpen: observable,
      forgotPasswordModalOpen: observable,
      resetPasswordModalOpen: observable,
      gatedRedirectLocation: observable,
      resetPasswordToken: observable,
      inviteToken: observable,
    });

    this.setUser(initialData.user);
  }

  setUser = (user) => {
    identifyUser(user, this.user);
    this.user = user;
  };

  signUp = async (signupRequest) => {
    await AuthApi.signup(signupRequest);
    const user = await UserApi.getUserMe();
    this.setUser(user);
    return user;
  };

  logIn = async (loginRequest) => {
    await AuthApi.loginUser(loginRequest);
    const user = await UserApi.getUserMe();
    this.setUser(user);
    return user;
  };

  logout = () => {
    trackEvent({ name: 'Log Out' });
    AuthApi.logout().finally(() => {
      Cookies.remove(SESSION_COOKIE_NAME, {
        domain: process.env.NEXT_PUBLIC_SESSION_COOKIE_DOMAIN,
      });
      identifyLoggedOutUser();
      window.location.href = '/';
    });
  };

  openLoginModal = () => {
    this.loginModalOpen = true;
  };

  closeLoginModal = () => {
    this.loginModalOpen = false;
  };

  openSignupModal = () => {
    this.signupModalOpen = true;
  };

  closeSignupModal = () => {
    this.signupModalOpen = false;
  };

  openForgotPasswordModal = () => {
    this.forgotPasswordModalOpen = true;
  };

  closeForgotPasswordModal = () => {
    this.forgotPasswordModalOpen = false;
  };

  openResetPasswordModal = () => {
    this.resetPasswordModalOpen = true;
  };

  closeResetPasswordModal = () => {
    this.resetPasswordModalOpen = false;
  };

  isUserRegistered = () => {
    return isUserRegistered(this.user);
  };
}

export function initializeAuthStore(initialData) {
  // Always make a new store if server, otherwise state is shared across pages
  if (isServer) {
    return new Store(initialData);
  }
  if (store === null) {
    store = new Store(initialData);
  }
  return store;
}
