import { UserRead } from '@headway/api/models/UserRead';
import { AuthApi } from '@headway/api/resources/AuthApi';
import { logException } from '@headway/shared/utils/sentry';
import { notifyError, notifySuccess } from '@headway/ui/utils/notify';

import { IAuthStore, IUiStore } from '../stores/withStores';

export enum UserVerificationStatus {
  REGISTERED_AND_VERIFIED = 'REGISTERED_AND_VERIFIED',
  REGISTERED_NOT_VERIFIED = 'REGISTERED_NOT_VERIFIED',
  UNREGISTERED = 'UNREGISTERED',
}

export const getVerificationStatus = (
  user: UserRead
): UserVerificationStatus => {
  if (isUserRegistered(user) && isUserVerified(user)) {
    return UserVerificationStatus.REGISTERED_AND_VERIFIED;
  }

  if (isUserRegistered(user) && !isUserVerified(user)) {
    return UserVerificationStatus.REGISTERED_NOT_VERIFIED;
  }

  return UserVerificationStatus.UNREGISTERED;
};

export const isUserRegistered = (user?: UserRead): boolean => {
  return !!user?.email;
};

const isUserVerified = (user?: UserRead): boolean => {
  return !!user?.isVerified;
};

export const verifyAccount = (
  AuthStore: IAuthStore,
  UiStore: IUiStore,
  onVerifySuccess: () => void
) => {
  const verificationStatus = getVerificationStatus(AuthStore.user);
  if (verificationStatus === UserVerificationStatus.REGISTERED_AND_VERIFIED) {
    onVerifySuccess();
  } else if (
    verificationStatus === UserVerificationStatus.REGISTERED_NOT_VERIFIED
  ) {
    UiStore.showSuccessAlert(
      'Check your email to verify your Headway account',
      'Your privacy is very important to us so we sent you an email. Please check it to verify your account. You must have a verified account before you can book a session.',
      async () => {
        try {
          await AuthApi.sendVerificationEmail(AuthStore.user.id, {
            redirectTo: window.location.pathname,
          });
          notifySuccess('Verification email sent');
        } catch (err) {
          notifyError('Failed to send verification email');
          logException(err);
        }
      },
      'Send another email'
    );
  } else {
    AuthStore.openSignupModal();
  }
};
