import { PatientBillingEventRead } from '@headway/api/models/PatientBillingEventRead';
import { PatientBillingEventsApi } from '@headway/api/resources/PatientBillingEventsApi';
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@headway/shared/react-query';

export const getUsePatientBillingEventsQueryKey = (
  userId?: number
): QueryKey => ['patient-billing-events', userId];

export const usePatientBillingEvents = (
  userId?: number,
  options?: UseQueryOptions<PatientBillingEventRead[]>
) =>
  useQuery(
    getUsePatientBillingEventsQueryKey(userId),
    () => PatientBillingEventsApi.getPatientBillingEvents(userId!),
    {
      staleTime: Infinity,
      ...options,
      enabled: !!userId && (options?.enabled ?? true),
    }
  );
