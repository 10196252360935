import React from 'react';

import { SectionContainer, SectionContainerProps } from './landing/Section';
import { theme } from './theme';

interface IllustrationSectionProps {
  title: React.ReactNode;
  illustrationSrc: string;
  sectionContainerProps?: SectionContainerProps;
}

export const IllustrationSection: React.FC<
  React.PropsWithChildren<IllustrationSectionProps>
> = ({ title, illustrationSrc, sectionContainerProps, children, ...rest }) => (
  <SectionContainer {...sectionContainerProps}>
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column-reverse',
        [theme.media.small]: { flexDirection: 'row' },
      }}
      {...rest}
    >
      <div
        css={{
          width: '100%',
          marginBottom: theme.space.xl6,
          [theme.media.small]: {
            width: '50%',
            marginBottom: 0,
            marginRight: theme.space.xl,
          },
        }}
      >
        <h2
          css={{
            color: theme.color.primary,
            marginBottom: theme.space.xl2,
          }}
        >
          {title}
        </h2>
        {children}
      </div>
      <div
        css={{
          position: 'relative',
          paddingBottom: '100%',
          width: '100%',
          [theme.media.small]: {
            paddingBottom: '50%',
            width: '50%',
          },
        }}
      >
        <div
          css={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            backgroundImage: `url(${illustrationSrc})`,
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        />
      </div>
    </div>
  </SectionContainer>
);
