import qs from 'qs';

const SEARCH_PARAM_STORAGE_KEY = 'searchParams';

function parseSearchParams() {
  return qs.parse(window.location.search, { ignoreQueryPrefix: true });
}

export function storeSearchParams() {
  const params = parseSearchParams();

  // only store param values if they are present, prevent overwriting wit empty
  if (Object.keys(params).length) {
    window.localStorage.setItem(
      SEARCH_PARAM_STORAGE_KEY,
      JSON.stringify(params)
    );
  }
}

export function retrieveStoredSearchParams() {
  const storedParams = window.localStorage.getItem(SEARCH_PARAM_STORAGE_KEY);

  return storedParams ? JSON.parse(storedParams) : {};
}
