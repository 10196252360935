import { ToggleButton as MuiToggleButton } from '@mui/material';
import React from 'react';

import { theme } from './theme';

export const ToggleButton = (props) => (
  <MuiToggleButton
    // component={Button}
    css={{
      textTransform: 'none',
      fontWeight: 'normal',
      fontSize: theme.fontSize.base,
      padding: '10px 30px',
      fontFamily: theme.fontFamily.postGrotesk,
      ...props.css,
    }}
    {...props}
  >
    {props.children}
  </MuiToggleButton>
);
