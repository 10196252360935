import NextImage, {
  ImageProps as NextImageProps,
  StaticImageData,
} from 'next/legacy/image';
import React from 'react';

import { CLOUD_FLARE_IMAGE_TRANSFORM } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { transformCloudflareImg } from '@headway/shared/utils/cloudflareImage';

export interface ImageProps {
  src: StaticImageData;
  alt: string;
  zoom?: boolean;
  sizes?: NextImageProps['sizes'];
}

export const Image: React.FC<ImageProps> = ({
  src,
  alt,
  zoom = false,
  sizes,
  ...rest
}) => {
  const flag = useFlag(CLOUD_FLARE_IMAGE_TRANSFORM, false);

  return (
    <div css={{ width: '100%', overflow: 'hidden' }} {...rest}>
      <NextImage
        {...(!flag || process.env.NODE_ENV === 'development'
          ? {}
          : {
              loader: transformCloudflareImg,
            })}
        src={src}
        alt={alt}
        layout="responsive"
        quality={100}
        sizes={sizes}
      />
    </div>
  );
};

export type { StaticImageData };
