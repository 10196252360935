import { Role } from '@headway/api/models/Role';

/*
 * NOTE: not use this function to gate displaying sensitive data, since
 * anything on the frontend is inherently at the mercy of the user.
 * Roles are enforced on the backend, and this logic simply prevents
 * us from displaying links to pages that won't load.
 */
export const canView = (allowedRoles: Role[], roles?: Role[]): boolean => {
  if (allowedRoles.length === 0) return true;
  const allowedSet = new Set(allowedRoles || []);
  return !!roles?.length && roles.some((role: Role) => allowedSet.has(role));
};

export const canViewAtlas = (roles: Role[]): boolean => {
  return canView([Role.INTERNAL_USER], roles);
};

export function canViewPhi(roles: Role[]): boolean {
  return canView([Role.ADMIN, Role.PHI_VIEWER], roles);
}

export function canViewPhiOrIsCareNavigator(roles: Role[]): boolean {
  return canView([Role.ADMIN, Role.PHI_VIEWER, Role.CARE_NAVIGATOR], roles);
}

export function canImpersonateProvider(roles: Role[]): boolean {
  return canView(
    [
      Role.AUTOMATIC_PROVIDER_IMPERSONATOR,
      Role.CONTEXTUAL_PROVIDER_IMPERSONATOR,
      Role.DEMO_USER_IMPERSONATOR,
      Role.LAUNCH_PROVIDER_IMPERSONATOR,
      Role.LIGHT_PROVIDER_IMPERSONATOR,
    ],
    roles
  );
}

export function canImpersonatePatient(roles: Role[]): boolean {
  return canView(
    [
      Role.AUTOMATIC_PATIENT_IMPERSONATOR,
      Role.CONTEXTUAL_PATIENT_IMPERSONATOR,
      Role.DEMO_USER_IMPERSONATOR,
      Role.LAUNCH_PATIENT_IMPERSONATOR,
      Role.LIGHT_PATIENT_IMPERSONATOR,
    ],
    roles
  );
}

export const canEditCredAdvanced = (roles: Role[]): boolean =>
  canView([Role.ADMIN, Role.CREDENTIALING_ADVANCED], roles);
