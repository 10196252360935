import AppBar from '@mui/material/AppBar';
import MaterialUiTabs from '@mui/material/Tabs';
import React from 'react';

export const Tabs = ({ children, ...props }) => {
  return (
    <AppBar position="static" color="default">
      <MaterialUiTabs
        textColor="primary"
        variant={'scrollable'}
        scrollButtons="auto"
        aria-label="scrollable tabs"
        indicatorColor="secondary"
        {...props}
      >
        {children}
      </MaterialUiTabs>
    </AppBar>
  );
};
