import React from 'react';

import { theme } from '@headway/ui/theme';

interface TipProps {
  title: React.ReactNode;
  children?: React.ReactNode;
}

export const Tip: React.FC<React.PropsWithChildren<TipProps>> = function Tip({
  title,
  children,
}) {
  return (
    <div
      css={{
        backgroundColor: theme.color.primaryBackground,
        borderRadius: 3,
        padding: `${theme.space.xs} ${theme.space.base} ${theme.space.base}`,
      }}
    >
      <strong>
        <span aria-hidden={true}>💡</span> Tip:
      </strong>{' '}
      {title}
      {children}
    </div>
  );
};
