import React from 'react';

import { FieldInput, FieldInputProps } from './FieldInput';
import { ShowHideIconToggleAdornment } from './ShowHideIconToggleAdornment';

type FieldPasswordInputProps = Omit<FieldInputProps, 'type'> & {
  autoComplete: 'new-password' | 'current-password';
};

export const FieldPasswordInput: React.FC<
  React.PropsWithChildren<FieldPasswordInputProps>
> = (props) => {
  const [showText, setShowText] = React.useState(false);
  return (
    <FieldInput
      type={showText ? 'text' : 'password'}
      endAdornment={
        <ShowHideIconToggleAdornment
          onToggle={() => setShowText(!showText)}
          currentlyShowing={showText}
        />
      }
      {...props}
    />
  );
};
