import styled from '@emotion/styled';
import React from 'react';

import { theme } from '../theme';

export const Metrics = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) => (
  <div
    className="Metrics"
    css={{
      width: `calc(100% + 2 * ${theme.space.base})`,
      margin: `-${theme.space.base}`,
      display: 'flex',
      flexWrap: 'wrap',
      '> div': {
        padding: theme.space.base,
        width: '100%',
        [theme.media.small]: {
          width: '50%',
        },
        [theme.media.medium]: {
          width: '25%',
        },
      },
    }}
    {...props}
  />
);

interface MetricProps {
  label: React.ReactNode;
  value: React.ReactNode;
}

export const Metric: React.FC<React.PropsWithChildren<MetricProps>> = ({
  label,
  value,
}) => (
  <div
    css={{
      marginTop: theme.space.xl,
    }}
  >
    <div
      css={{
        fontFamily: theme.fontFamily.canelaText,
        fontSize: theme.fontSize.xl5,
        color: theme.color.primary,
        margin: `-${theme.space.xl} 0 ${theme.space.base}`,
        [theme.media.medium]: {
          fontSize: theme.fontSize.xl6,
        },
      }}
    >
      {value}
    </div>
    <div
      css={{
        fontFamily: theme.fontFamily.postGrotesk,
        fontSize: theme.fontSize.base,
      }}
    >
      {label}
    </div>
  </div>
);
