import { makeObservable, observable } from 'mobx';
import React from 'react';

import { isServer } from '@headway/shared/utils/isServer';

import aetnaCard from '../assets/img/cards/aetna.jpg';
import blueCrossBlueShieldCard from '../assets/img/cards/blue-cross-blue-shield.jpg';
import cignaCard from '../assets/img/cards/cigna.jpg';
import emblemCard from '../assets/img/cards/emblem.jpg';
import empireCard from '../assets/img/cards/empire.jpg';
import oscarCard from '../assets/img/cards/oscar.jpg';
import oxfordCard from '../assets/img/cards/oxford.jpg';
import unitedCard from '../assets/img/cards/united.jpg';
import { Image } from '../components/Image/Image';

let uiStore = null;
const currentCarriers = [1, 2, 3, 4, 5, 10, 11, 23];

class Store {
  showAlert = false;
  alertMessageType = '';
  alertMessageTitle = '';
  alertMessage = '';
  alertButtonText = 'Close';
  alertButtonColor = '';
  alertCbOnClose = () => {};
  mobileMenuOpen = false;
  providerId = undefined;

  constructor(initialData = {}) {
    makeObservable(this, {
      showAlert: observable,
      alertMessageType: observable,
      alertMessageTitle: observable,
      alertMessage: observable,
      alertButtonText: observable,
      alertButtonColor: observable,
      alertCbOnClose: observable,
      mobileMenuOpen: observable,
      providerId: observable,
    });

    this.showAlert = initialData.showAlert || false;
    this.alertMessageType = initialData.alertMessageType || '';
    this.alertMessageTitle = initialData.alertMessageTitle || '';
    this.alertMessage = initialData.alertMessage || '';
    this.alertButtonText = initialData.alertButtonText || 'Close';
    this.alertButtonColor = initialData.alertButtonColor || '';
    this.alertButtonOnClick = initialData.alertButtonOnClick || (() => {});
    this.alertCbOnClose = initialData.alertCbOnClose || (() => {});
    this.providerId = initialData.providerId || undefined;
  }

  showWarningAlert = (
    title,
    message,
    alertCbOnClose = () => {},
    alertButtonText = 'Close',
    alertButtonColor = '',
    alertButtonOnClick = () => {}
  ) => {
    this.showAlert = true;
    this.alertMessageType = 'warning';
    this.alertMessageTitle = title;
    this.alertMessage = message;
    this.alertCbOnClose = alertCbOnClose;
    this.alertButtonText = alertButtonText;
    this.alertButtonColor = alertButtonColor;
    this.alertButtonOnClick = alertButtonOnClick;
  };

  showSuccessAlert = (
    title,
    message,
    alertCbOnClose = () => {},
    alertButtonText = 'Close',
    alertButtonColor = '',
    alertButtonOnClick = () => {}
  ) => {
    this.showAlert = true;
    this.alertMessageType = 'success';
    this.alertMessageTitle = title;
    this.alertMessage = message;
    this.alertCbOnClose = alertCbOnClose;
    this.alertButtonText = alertButtonText;
    this.alertButtonColor = alertButtonColor;
    this.alertButtonOnClick = alertButtonOnClick;
  };

  hideAlert = () => {
    this.showAlert = false;
    this.alertMessageType = '';
    this.alertMessageTitle = '';
    this.alertMessage = '';
    this.alertButtonText = 'Close';
    this.alertButtonColor = '';
    this.alertCbOnClose = () => {};
    this.alertButtonOnClick = () => {};
  };

  toggleMobileMenu = () => {
    this.mobileMenuOpen = !this.mobileMenuOpen;
  };

  canShowInsuranceHelperModal = (frontEndCarrierId) => {
    return currentCarriers.includes(frontEndCarrierId);
  };

  showInsuranceHelperModal = (frontEndCarrierId) => {
    if (currentCarriers.includes(frontEndCarrierId)) {
      this.showAlert = true;
      this.alertMessageType = 'success';
      this.alertMessageTitle = 'Finding your Member ID';
      this.alertMessage = (
        <div>
          <Image
            alt="Insurance card"
            src={
              frontEndCarrierId == 1
                ? aetnaCard
                : frontEndCarrierId == 2
                ? blueCrossBlueShieldCard
                : frontEndCarrierId == 3
                ? cignaCard
                : frontEndCarrierId == 4
                ? emblemCard
                : frontEndCarrierId == 5
                ? empireCard
                : frontEndCarrierId == 10
                ? oxfordCard
                : frontEndCarrierId == 11
                ? unitedCard
                : oscarCard
            }
            sizes="375px"
          />
        </div>
      );
      this.alertButtonText = undefined;
    }
  };

  getProviderId = (id) => {
    this.providerId = id;
  };
}

export function initializeUiStore(initialData) {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return new Store(initialData);
  }
  if (uiStore === null) {
    uiStore = new Store(initialData);
  }
  return uiStore;
}

export { uiStore };
