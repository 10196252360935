import React from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { UserApi } from '@headway/api/resources/UserApi';

const ImpersonatingUserContext = React.createContext<UserRead | undefined>(
  undefined
);

interface ImpersonatingUserProviderProps {
  onError: (err: unknown) => void;
  actingUserId?: number;
  children: React.ReactNode;
}

export const ImpersonatingUserProvider: React.FC<
  ImpersonatingUserProviderProps
> = ({ onError, actingUserId, children }) => {
  const [impersonatingUser, setImpersonatingUser] = React.useState<
    UserRead | undefined
  >(undefined);

  React.useEffect(() => {
    const fetchImpersonatingUser = async () => {
      try {
        setImpersonatingUser(undefined);
        const user = await UserApi.getOriginalUserMe();
        setImpersonatingUser(user);
      } catch (err) {
        onError(err);
      }
    };

    fetchImpersonatingUser();
  }, [onError, actingUserId]);

  return (
    <ImpersonatingUserContext.Provider value={impersonatingUser}>
      {children}
    </ImpersonatingUserContext.Provider>
  );
};

export const useImpersonatingUser = () =>
  React.useContext(ImpersonatingUserContext);

export const ImpersonatingUserConsumer = ImpersonatingUserContext.Consumer;
