import { Global } from '@emotion/react';
import CloseIcon from '@mui/icons-material/CloseTwoTone';
import { Drawer, IconButton, useMediaQuery } from '@mui/material';
import React from 'react';

import { theme } from './theme';
import { VisuallyHidden } from './VisuallyHidden';

export const SideSheet = ({
  open,
  onClose,
  title,
  width,
  divider,
  blurSelector,
  children,
  ...rest
}) => {
  const matches = useMediaQuery(`(max-width: ${theme.breakpoints.small}px)`);
  return (
    <>
      <Global
        styles={{
          [blurSelector]: {
            filter: open ? 'blur(5px)' : undefined,
          },
        }}
      />
      <Drawer
        open={open}
        onClose={onClose}
        transitionDuration={150}
        anchor={matches ? 'bottom' : 'right'}
        css={{
          '.MuiPaper-root': {
            width: '100%',
            height: '100%',
          },
          [theme.media.medium]: {
            '.MuiPaper-root': {
              width: 'initial',
            },
          },
        }}
        {...rest}
      >
        <div
          css={{
            padding: theme.space.base,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: divider
              ? `1px solid ${theme.color.border}`
              : undefined,
          }}
        >
          <h3
            css={{
              margin: 0,
              color: theme.color.primary,
            }}
          >
            {title}
          </h3>
          <IconButton
            onClick={onClose}
            css={{
              margin: `-${theme.space.base} -${theme.space.xs}`,
            }}
            size="large"
          >
            <CloseIcon />
            <VisuallyHidden>Close</VisuallyHidden>
          </IconButton>
        </div>
        <div css={{ padding: theme.space.base }}>{children}</div>
      </Drawer>
    </>
  );
};
