import { FormHelperText } from '@mui/material';
import { FormHelperTextProps } from '@mui/material/FormHelperText';
import { useField } from 'formik';
import React, { FC } from 'react';

import { FieldControlContext } from './FieldControl';

type Props = FormHelperTextProps;

const FieldHelperText: FC<React.PropsWithChildren<Props>> = ({
  children,
  ...props
}) => {
  const { name } = React.useContext(FieldControlContext);
  const [, meta] = useField(name);

  if (meta.error && meta.touched) {
    // Show error text instead of helper text
    return null;
  }

  return (
    <FormHelperText variant="standard" {...props}>
      {children}
    </FormHelperText>
  );
};

export { FieldHelperText };
