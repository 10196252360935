import moment from 'moment';

import { PatientBillingEventRead } from '@headway/api/models/PatientBillingEventRead';
import { PatientBillingEventStatus } from '@headway/api/models/PatientBillingEventStatus';

export interface PartitionedBillingEvents {
  past: PatientBillingEventRead[];
  needsAttention: PatientBillingEventRead[];
  upcoming: PatientBillingEventRead[];
}

export const partitionBillingEvents = ({
  billingEvents,
  years,
  types,
}: {
  billingEvents: PatientBillingEventRead[];
  years?: string[];
  types?: string[];
}): PartitionedBillingEvents =>
  billingEvents.reduce(
    (acc, billingEvent) => {
      if (
        (years?.length &&
          !years.includes(
            moment(billingEvent.billingEventDate).year().toString()
          )) ||
        (types?.length && !types.includes(billingEvent.billingEventType))
      ) {
        return acc;
      }
      if (
        billingEvent.status === PatientBillingEventStatus.CHARGED ||
        billingEvent.status === PatientBillingEventStatus.REFUNDED
      ) {
        acc.past.push(billingEvent);
      } else if (
        billingEvent.status ===
          PatientBillingEventStatus.UPCOMING_AUTO_CHARGE ||
        billingEvent.status === PatientBillingEventStatus.SCHEDULED_PAYMENT
      ) {
        acc.upcoming.push(billingEvent);
      } else {
        acc.needsAttention.push(billingEvent);
      }
      return acc;
    },
    {
      past: [] as PatientBillingEventRead[],
      needsAttention: [] as PatientBillingEventRead[],
      upcoming: [] as PatientBillingEventRead[],
    }
  );
