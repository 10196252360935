import { createFilterOptions } from '@mui/material/useAutocomplete';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';

import { FieldAutocomplete, FieldAutocompleteProps } from './FieldAutocomplete';
import { FieldControlContext } from './FieldControl';

type InputOption = { inputValue: string; title: string };
type Option = string | number | InputOption;

const isInputOption = (
  option: Option | Option[] | null
): option is InputOption =>
  !!option &&
  !Array.isArray(option) &&
  typeof option !== 'string' &&
  typeof option !== 'number';

const isLastItemInputOption = (
  options: Option | Option[] | null
): options is InputOption[] => {
  if (!Array.isArray(options) || options.length < 1) {
    return false;
  }
  const lastItem = options[options.length - 1];
  return typeof lastItem !== 'string' && typeof lastItem !== 'number';
};

/**
 * Formik-enabled MUI Autocomplete field that shows "Add X" as a dropdown option
 * when entering free solo text
 */
export const FieldAutocompleteCreateOption: FC<
  React.PropsWithChildren<FieldAutocompleteProps<Option>>
> = ({ ...props }) => {
  const { name } = React.useContext(FieldControlContext);
  const { setFieldValue } = useFormikContext<{
    [name: string]: any;
  }>();

  return (
    <FieldAutocomplete
      // https://mui.com/components/autocomplete/#creatable
      clearOnBlur
      filterOptions={(options, filterOptionsState) => {
        const filter = createFilterOptions<Option>();
        const filtered = filter(options, filterOptionsState);
        // Suggest the creation of a new value
        if (filterOptionsState.inputValue !== '') {
          filtered.push({
            inputValue: filterOptionsState.inputValue,
            title: `Add "${filterOptionsState.inputValue}"`,
          });
        }
        return filtered;
      }}
      freeSolo
      freeSoloCreateOption
      getOptionLabel={(option) =>
        isInputOption(option) ? option.inputValue : option.toString()
      }
      onChange={(_, value) => {
        if (isInputOption(value) && !!value.inputValue) {
          // For single select, set value to user input
          setFieldValue(name, value.inputValue);
        } else if (
          props.multiple &&
          isLastItemInputOption(value) &&
          value[value.length - 1].inputValue
        ) {
          // For multiselect, add user input to value array
          setFieldValue(name, [
            ...value.slice(0, value.length - 1),
            value[value.length - 1].inputValue,
          ]);
        } else {
          // If not selecting input, i.e. selecting an existing option
          setFieldValue(name, value);
        }
      }}
      renderOption={(props, option) => (
        <li {...props}>{isInputOption(option) ? option.title : option}</li>
      )}
      {...props}
    />
  );
};
