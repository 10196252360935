/* GTM const */
const gtmId = 'GTM-TQG7664';
const gtm_auth = `&gtm_auth=`;
const gtm_preview = `&gtm_preview=`;
const dataLayerName = 'dataLayer';
const events = {};

export const noScript = () => {
  const iframe = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}${gtm_auth}${gtm_preview}&gtm_cookies_win=x"
        height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;

  const noscript = document.createElement('noscript');
  noscript.innerHTML = iframe;
  return noscript;
};

export const gtmScript = (nonce: string) => {
  const gtmScript = `
  (function(w,d,s,l,i){w[l]=w[l]||[];
    w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js', ${JSON.stringify(
      events
    ).slice(1, -1)}});
    var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
    j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+'${gtm_auth}${gtm_preview}&gtm_cookies_win=x';
    var n=d.querySelector('[nonce]');
    n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));
    f.parentNode.insertBefore(j,f);
  })(window,document,'script','${dataLayerName}','${gtmId}');`;

  const script = document.createElement('script');
  script.innerHTML = gtmScript;
  script.setAttribute('nonce', `${nonce}`);
  script.setAttribute('data-nonce', `${nonce}`);
  script.setAttribute('id', 'gtmScript');
  return script;
};
