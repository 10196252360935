import React from 'react';

type ApplicationName = 'AGORA' | 'CARE' | 'MARKETING';

const context = React.createContext<ApplicationName>('AGORA');

const ApplicationNameContextProvider = context.Provider;

function useApplicationName() {
  return React.useContext(context);
}

export { useApplicationName, ApplicationNameContextProvider };
