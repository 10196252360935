import { ThemeChoice } from './constants';
import { getMuiTheme } from './MuiTheme';
import { legacyTheme } from './theme';
import { ThemeContext, ThemeProvider } from './ThemeProvider';

export type { ThemeProviderProps } from './ThemeProvider';
export {
  legacyTheme as theme,
  ThemeChoice,
  ThemeProvider,
  ThemeContext,
  getMuiTheme,
};
