import styled from '@emotion/styled';
import React from 'react';

import { theme } from '@headway/ui/theme';

export const Logo = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) => (
  <div
    className="Logo"
    css={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      fontFamily: theme.fontFamily.canelaText,
      fontSize: theme.fontSize.xl3,
    }}
    {...props}
  />
);

export const Logos: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const childCount = React.Children.toArray(children).length;
  return (
    <div
      css={{
        display: 'grid',
        gridGap: theme.space.xl2,
        gridTemplateColumns: `repeat(1, 1fr)`,
        margin: `${theme.space.base} auto 0 auto`,
        [theme.media.small]: {
          gridTemplateColumns: `repeat(${childCount}, 1fr)`,
          margin: `${theme.space.xl2} auto`,
          width: '100%',
        },
      }}
    >
      {children}
    </div>
  );
};
