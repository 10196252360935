import { Clear } from '@mui/icons-material';
import { LocalizationProvider, TimePicker, TimePickerProps } from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';

import { FieldControlContext } from './FieldControl';
import { useField } from './useField';
import { noop } from './utils';

type Props = Partial<TimePickerProps> & {
  clearable?: boolean;
  format?: string;
  onChange?: (e: any) => any;
};

export const FieldTimePicker: FC<React.PropsWithChildren<Props>> = ({
  onChange = noop,
  clearable,
  ...props
}) => {
  const { name, id } = React.useContext(FieldControlContext);
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField({ name, ...props });

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <TimePicker
        {...props}
        {...field}
        // If field isn't on the rawData object, we need to default
        // to '' in order to display an empty date field
        value={field.value !== undefined ? field.value : ''}
        onChange={(date) => {
          onChange(date);
          if (date && date.isValid()) {
            setFieldValue(name, date.toISOString());
          } else {
            setFieldValue(name, '');
          }
        }}
        css={{ margin: 0 }}
        renderInput={(inputProps) => (
          <TextField
            {...inputProps}
            id={id}
            data-testid={name}
            error={meta.error !== undefined && meta.touched}
            helperText={meta.error}
            variant="standard"
            InputProps={{
              ...inputProps.InputProps,
              ...(clearable && field.value
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            setFieldValue(name, null);
                          }}
                        >
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                : {}),
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
