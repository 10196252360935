import { GetServerSidePropsContext } from 'next';
import { v4 as uuidv4 } from 'uuid';

import { UserRead } from '@headway/api/models/UserRead';

export type GetServerSidePropsContextWithUser = GetServerSidePropsContext & {
  req: ServerSideRequestWithUserMeData;
};
interface Session {
  id: typeof uuidv4;
}
export type ServerSideRequestWithUserMeData =
  GetServerSidePropsContext['req'] & {
    userMeData?: UserRead;
    path?: string;
    session?: Session;
    isBot?: boolean;
  };

export type ServerSideResponse = GetServerSidePropsContext['res'];

export function getAuthCookie(
  req: ServerSideRequestWithUserMeData,
  res: ServerSideResponse
) {
  const resSetCookie = res.getHeader('set-cookie');

  // normalize the set-cookie header to a list of strings
  const resSetCookieList = Array.isArray(resSetCookie)
    ? resSetCookie
    : typeof resSetCookie === 'string'
    ? [resSetCookie]
    : [];

  // find the access_token cookie
  const accessTokenCookie = resSetCookieList
    .find((cookie) => cookie.includes('access_token'))
    ?.split(';')[0];

  // favor the access_token from set-cookie over the request cookie
  return accessTokenCookie || req.headers.cookie;
}
