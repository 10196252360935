import React from 'react';

import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';
import { getCarrierMemberIdData } from '@headway/shared/utils/memberIdValidationData';
import { getValidationResult } from '@headway/shared/utils/memberIdValidationUtils';

interface MemberIdValidationGuidanceProps {
  memberId: string;
  frontEndCarrierId: number;
}

export const MemberIdValidationGuidance: React.FC<
  MemberIdValidationGuidanceProps
> = ({ memberId, frontEndCarrierId }) => {
  const { carriersById } = useFrontEndCarriers();
  const memberIdData = getCarrierMemberIdData(frontEndCarrierId);

  const validationResult = React.useMemo(
    () => getValidationResult(memberId, frontEndCarrierId),
    [memberId, memberIdData]
  );
  if (!validationResult) return null;

  const {
    variant,
    invalidPrefix,
    invalidSuffix,
    invalidFormat,
    formattedPrefixes,
    formattedSuffixes,
    minLength,
    maxLength,
    characterTypes,
  } = validationResult;

  return (
    <div
      className="m-4"
      data-testid="MemberIdValidationGuidanceCard"
      data-variant={variant}
    >
      <GuidanceCard variant={variant}>
        <SubBodyText>
          <div className="font-bold">
            {`It looks like this might not be a valid member id. ${
              carriersById[frontEndCarrierId]?.name ?? 'Member'
            } IDs:`}
          </div>
          {formattedPrefixes && (
            <li className={invalidPrefix ? 'text-bold' : ''}>
              Start with: {formattedPrefixes}
            </li>
          )}
          {formattedSuffixes && (
            <li className={invalidSuffix ? 'text-bold' : ''}>
              End with: {formattedSuffixes}
            </li>
          )}
          <li className={invalidFormat ? 'text-bold' : ''}>
            {minLength === maxLength
              ? `Are ${minLength} characters long`
              : `Are between ${minLength} to ${maxLength} characters long`}{' '}
            and can contain {characterTypes}
          </li>
        </SubBodyText>
      </GuidanceCard>
    </div>
  );
};
