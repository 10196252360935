import React from 'react';

import { theme } from '@headway/ui/theme';

interface FeatureProps {
  title: React.ReactNode;
  icon?: React.ComponentType<React.PropsWithChildren<unknown>>;
}

export const Feature: React.FC<React.PropsWithChildren<FeatureProps>> = ({
  children,
  title,
  icon: Icon,
}) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    }}
  >
    <div
      css={{
        marginBottom: theme.space.lg,
      }}
    >
      {Icon ? (
        <span
          css={{
            background: theme.color.primaryBackground,
            borderRadius: '50%',
            padding: theme.space.xs,
            marginBottom: theme.space.base,
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Icon css={{ color: theme.color.primary }} />
        </span>
      ) : null}
      <h4
        css={{
          fontSize: theme.fontSize.xl,
          margin: 0,
          marginBottom: 0,
        }}
      >
        {title}
      </h4>
    </div>

    <p
      css={{
        fontFamily: theme.fontFamily.postGrotesk,
        fontSize: theme.fontSize.lg,
      }}
    >
      {children}
    </p>
  </div>
);

export const Features: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const childCount = React.Children.toArray(children).length;
  return (
    <div
      css={{
        display: 'grid',
        gridGap: theme.space.xl2,
        gridTemplateColumns: `repeat(1, 1fr)`,
        [theme.media.small]: {
          gridGap: theme.space.xl4,
          gridTemplateColumns: `repeat(${childCount}, 1fr)`,
        },
      }}
    >
      {children}
    </div>
  );
};
