import { CSSObject } from '@emotion/css';
import React from 'react';

import { theme } from './theme';

interface CardSectionProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  /**
   * Determines if the header, description, and children are rendered side by side or in one column
   */
  split?: boolean;
  isSubsection?: boolean;
  id?: string;
  children: React.ReactNode;
}

export const CardSection: React.FC<
  React.PropsWithChildren<CardSectionProps>
> = ({
  title,
  description,
  split = true,
  isSubsection = false,
  id = '',
  children,
  ...rest
}) => (
  <div
    {...rest}
    css={{
      ':not(:first-child)': {
        paddingTop: 0,
      },
    }}
  >
    <div
      css={{
        padding: theme.space.base,
        background: theme.color.white,
        borderRadius: 3,
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          [theme.media.small]: {
            display: 'flex',
            flexDirection: split ? 'row' : 'column',
            justifyContent: 'space-between',
          },
        }}
      >
        <div
          id={id}
          css={{
            [theme.media.small]: {
              width: split ? '37.5%' : '100%',
            },
            marginBottom: split || !description ? 0 : theme.space.base,
          }}
        >
          <Header
            isSubsection={isSubsection}
            css={{
              color: theme.color.primary,
              marginTop: 0,
              marginBottom: theme.space.base,
            }}
          >
            {title}
          </Header>
          {description}
        </div>
        <div
          css={{
            width: '100%',
            [theme.media.small]: {
              width: split ? '55%' : '100%',
            },
          }}
        >
          {children}
        </div>
      </div>
    </div>
  </div>
);

interface HeaderProps
  extends Pick<CardSectionProps, 'isSubsection' | 'children'> {}

const Header = ({ isSubsection, children, ...rest }: HeaderProps) => {
  return isSubsection ? (
    <h6 {...rest}>{children}</h6>
  ) : (
    <h5 {...rest}>{children}</h5>
  );
};
