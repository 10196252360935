// formatjs polyfill imports MUST maintain specfic ordering:
//  1. polyfills before data
//  2. Locale before ListFormat (dependency)
// Prettier will clober this and we cannot opt out at this time due to plugin limitations
//   https://github.com/trivago/prettier-plugin-sort-imports/issues/112
// Therefore, these imports (with side effects) are isolated in their own file,
// which is ignored by prettier
// Import polyfills
// Import data
import '@formatjs/intl-listformat/locale-data/en';
import '@formatjs/intl-listformat/polyfill';
import '@formatjs/intl-locale/polyfill';
