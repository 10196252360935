import React from 'react';

import {
  FORETHOUGHT_LIVE_CHAT_ENABLED,
  LIVE_CHAT_INSURANCE_VERIFICATION,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { formatPatientName } from '@headway/shared/utils/patient';
import { ForethoughtScriptProvider as BaseForethoughtScriptProvider } from '@headway/ui/Forethought';

import { useRouter } from '../hooks/useRouter';
import { withStores, WithStoresChildProps } from '../stores/withStores';
import {
  getVerificationStatus,
  UserVerificationStatus,
} from '../utils/verifyAccount';

const ForethoughtScriptImpl: React.FC<
  WithStoresChildProps & React.PropsWithChildren
> = ({ AuthStore, children }) => {
  const isForethoughtFFEnabled = useFlag('forethoughtEnabled');
  const isLiveChatEnabled = useFlag(FORETHOUGHT_LIVE_CHAT_ENABLED);
  const isInsuranceVerificationViaLiveChatEnabled = useFlag(
    LIVE_CHAT_INSURANCE_VERIFICATION,
    false
  );

  const isRegisteredUser =
    getVerificationStatus(AuthStore.user) ===
    UserVerificationStatus.REGISTERED_AND_VERIFIED;

  const router = useRouter();
  const openChatbot = router.query.openChatbot === 'true';

  if (!isForethoughtFFEnabled) {
    return <>{children}</>;
  }

  if (!isRegisteredUser || !AuthStore.user.email) {
    // Not logged in
    if (isForethoughtFFEnabled && openChatbot) {
      // If they're not logged in, but the flag is enabled, as for login when we want to force the bot open
      AuthStore.openLoginModal();
    }

    return <>{children}</>;
  }

  const isProviderOrGPOwner =
    isRegisteredUser &&
    (!!AuthStore.user?.providerId ||
      !!AuthStore.user?.groupPracticeUsers?.length);

  const workflowTagValue = isProviderOrGPOwner
    ? undefined
    : 'I receive mental health services';

  const name = formatPatientName(AuthStore.user);

  return (
    <BaseForethoughtScriptProvider
      mambaHost={process.env.API_URL}
      zendeskAPIKey={process.env.NEXT_PUBLIC_ZENDESK_CHAT_API_KEY}
      apiKey={process.env.NEXT_PUBLIC_FORETHOUGHT_DATA_API_KEY}
      email={AuthStore.user.email}
      name={name}
      defaultWorkflowTag={workflowTagValue}
      providerID={AuthStore.user?.providerId}
      userID={AuthStore.user.id}
      autoOpen={openChatbot}
      liveChatEnabled={isLiveChatEnabled}
      insuranceVerificationLiveChat={isInsuranceVerificationViaLiveChatEnabled}
    >
      {children}
    </BaseForethoughtScriptProvider>
  );
};

const ForethoughtScript = withStores(ForethoughtScriptImpl);
export { ForethoughtScript };
