import { MenuItem } from '@mui/material';
import React from 'react';

import { FieldSelect } from './FieldSelect';

interface SelectOption {
  label: string;
  value: string;
}

interface FieldSelectWithOptionsProps {
  options: SelectOption[];
  allowNull?: boolean;
}

export const FieldSelectWithOptions: React.FC<
  React.PropsWithChildren<FieldSelectWithOptionsProps>
> = ({ options, allowNull, ...props }) => {
  const mappedOptions = options.map((option, key) => {
    return (
      <MenuItem key={key} value={option.value}>
        {option.label}
      </MenuItem>
    );
  });

  if (allowNull) {
    mappedOptions.push(
      // Hack: `null` is cast to `any` because MenuItem does not accept null as a type.
      // Ideally we would use `undefined` here but the functional impact of that change is unknown.
      <MenuItem key="null" value={null as any}>
        <em>None</em>
      </MenuItem>
    );
  }

  return (
    <FieldSelect
      css={{
        '.MuiOutlinedInput-input': {
          padding: '10px 14px',
          fontSize: '14px',
        },
      }}
      {...props}
    >
      {mappedOptions}
    </FieldSelect>
  );
};
