import { Paper } from '@mui/material';
import React from 'react';

import { theme } from '@headway/ui/theme';

export const TabsWrapper: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
  ...props
}) => {
  return (
    <Paper
      css={{
        flexGrow: 1,
        width: '100%',
        ' .MuiPaper-elevation4': {
          boxShadow: 'none',
        },
        ' .MuiTabs-root': {
          borderRadius: `4px 4px 0 0`,
        },
        ' .MuiTabScrollButton-root': {
          overflow: 'hidden',
          opacity: 1,
          borderBottom: `1px solid ${theme.color.border}`,
          backgroundColor: theme.color.white,
        },
        ' .MuiTabScrollButton-root.Mui-disabled .MuiSvgIcon-root': {
          opacity: 0,
        },
        ' .MuiTab-root': {
          borderStyle: 'solid',
          borderColor: theme.color.border,
          borderWidth: `0 0 1px 0`,
          backgroundColor: theme.color.white,
          color: theme.color.darkGray,
          fontSize: theme.fontSize.base,
          fontFamily: theme.fontFamily.postGrotesk,
          textTransform: 'none',
          [theme.media.medium]: {
            minWidth: '120px',
            paddingLeft: 0,
            paddingRight: 0,
          },
          maxWidth: 'none',
        },
        ' .Mui-selected': {
          borderBottom: '1px solid transparent',
          borderStyle: 'solid',
          borderColor: `${theme.color.primary} ${theme.color.border} ${theme.color.border} ${theme.color.border}`,
          borderWidth: `3px 1px 0 1px`,
          ':first-child': {
            borderLeft: 'none',
          },
          ':last-child': {
            borderRight: 'none',
          },
        },
      }}
      {...props}
    >
      {children}
    </Paper>
  );
};
