import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import React, { FC } from 'react';

import { VisuallyHidden } from '../VisuallyHidden';

export const ShowHideIconToggleAdornment: FC<
  React.PropsWithChildren<{
    onToggle: () => void;
    currentlyShowing: boolean;
  }>
> = ({ onToggle, currentlyShowing }) => (
  <InputAdornment position="end">
    <VisuallyHidden aria-live="polite">
      {currentlyShowing ? 'Showing password' : 'Hiding password'}
    </VisuallyHidden>
    <IconButton
      aria-label={currentlyShowing ? 'Hide password' : 'Show password'}
      onClick={onToggle}
      onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
      }}
      size="large"
    >
      {currentlyShowing ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  </InputAdornment>
);
