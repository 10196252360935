import React, { ComponentType } from 'react';

import { Button } from './Button';
import { theme } from './theme';

export enum ConfirmationFormType {
  PENDING = 'PENDING',
  INVALID = 'INVALID',
}

interface ConfirmationFormProps {
  ctaColor: string;
  isUpdating?: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  ctaText?: string;
  closeText?: string;
  hasAffectedSteps?: boolean;
  type?: ConfirmationFormType;
  CustomButtons?: ComponentType<React.PropsWithChildren<unknown>>;
}

export const ConfirmationForm: React.FC<
  React.PropsWithChildren<ConfirmationFormProps>
> = ({
  ctaColor,
  isUpdating,
  onClose,
  onSubmit,
  ctaText,
  closeText,
  children,
  CustomButtons,
}) => {
  return (
    <>
      <div
        css={{
          marginBottom: 40,
          fontFamily: theme.fontFamily.postGrotesk,
          fontSize: theme.fontSize.lg,
        }}
      >
        {children}
      </div>
      <div
        css={{
          display: 'flex',
          marginTop: theme.space.xl2,
          justifyContent: 'flex-end',
        }}
      >
        {!!CustomButtons ? (
          <CustomButtons />
        ) : (
          <>
            <Button variant="outlined" onClick={onClose}>
              {`${closeText || 'Continue'}`}
            </Button>
            <Button
              variant="contained"
              css={{
                marginLeft: theme.space.sm,
                backgroundColor: ctaColor,
              }}
              disabled={isUpdating}
              onClick={onSubmit}
              data-testid="confirmButton"
            >
              {`${ctaText || 'Confirm'}`}
            </Button>
          </>
        )}
      </div>
    </>
  );
};
