import { UseQueryResult } from '@tanstack/react-query';
import { createContext, useContext, useMemo } from 'react';

import { PatientBillingEventRead } from '@headway/api/models/PatientBillingEventRead';
import { UserRead } from '@headway/api/models/UserRead';
import { BILLING_PAGE_EXPERIENCE_V2 } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';

import { usePatientBillingEvents } from '../hooks/usePatientBillingEvents';
import {
  partitionBillingEvents,
  PartitionedBillingEvents,
} from '../utils/billingEvents';
import {
  getVerificationStatus,
  UserVerificationStatus,
} from '../utils/verifyAccount';

export const BillingEventsContext = createContext<{
  isLoading: boolean;
  billingEvents: PatientBillingEventRead[];
  partitionedBillingEvents: PartitionedBillingEvents;
  refetch: (options?: {
    throwOnError: boolean;
    cancelRefetch: boolean;
  }) => Promise<UseQueryResult>;
}>({
  isLoading: true,
  billingEvents: [],
  partitionedBillingEvents: {
    needsAttention: [],
    past: [],
    upcoming: [],
  },
  refetch: async (options?: {
    throwOnError: boolean;
    cancelRefetch: boolean;
  }) => Promise.resolve({} as UseQueryResult),
});

export const BillingEventsProvider = ({
  children,
  user,
}: {
  children: React.ReactNode;
  user?: UserRead;
}) => {
  const isRegisteredAndVerifiedUser =
    !!user &&
    getVerificationStatus(user) ===
      UserVerificationStatus.REGISTERED_AND_VERIFIED;
  const isEligibleForBillingPageV2 = useFlag(BILLING_PAGE_EXPERIENCE_V2, false);
  const {
    data: billingEvents = [],
    isLoading,
    refetch,
  } = usePatientBillingEvents(user?.id, {
    enabled:
      isEligibleForBillingPageV2 && isRegisteredAndVerifiedUser && !!user,
  });

  const partitionedBillingEvents = useMemo(
    () => partitionBillingEvents({ billingEvents }),
    [billingEvents]
  );

  return (
    <BillingEventsContext.Provider
      value={{ isLoading, billingEvents, partitionedBillingEvents, refetch }}
    >
      {children}
    </BillingEventsContext.Provider>
  );
};

export const useBillingEvents = () => useContext(BillingEventsContext);
