import React from 'react';

import { Role } from '@headway/api/models/Role';
import { canView } from '@headway/shared/utils/access';

interface RestrictAccessToUsersWithRolesProps {
  allowedRoles: Role[];
  fallbackComponent?: React.ReactNode;
  roles: Role[];
  children: React.ReactNode;
}

export const RestrictAccessToUsersWithRoles = ({
  roles,
  allowedRoles,
  children,
  fallbackComponent,
}: RestrictAccessToUsersWithRolesProps) => {
  if (canView(allowedRoles, roles)) return <>{children}</>;
  if (fallbackComponent) return <>{fallbackComponent}</>;
  return null;
};
